interface DebounceFn<T extends (...args: any) => any> {
  cancel: () => void
  (...args: Parameters<T>): void
}

/**
 * debounce function
 * @param func callback after timeout
 * @param timeout in ms
 * @returns
 */
export function debounce<T extends (...args: any[]) => void>(
  func: T,
  timeout = 300,
): DebounceFn<T> {
  let timer: ReturnType<typeof setTimeout>

  function cancel() {
    if (timer) {
      clearTimeout(timer)
    }
  }

  function debounced(this: any, ...args: Parameters<T>): void {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }

  debounced.cancel = cancel

  return debounced
}
